import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const Commission = () => {
  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const nodeMode = process.env.NODE_ENV;
  let baseUrl;

  if (nodeMode === "development") {
    baseUrl = beckendLocalApiUrl;
  } else {
    baseUrl = beckendLiveApiUrl;
  }

  const [WebSitesettings, setWebsiteSettings] = useState("");

  const fetchData = async () => {
    console.log("fetch data");
    const response = await fetch(baseUrl + "settings/data");
    const data = await response.json();
    console.log(data)
    return setWebsiteSettings(data);
  };
  useEffect(() => {
    fetchData();
  }, []);


  return (
    <div>
      {" "}
      <div
        className="collapseCard commission-background-login p-0"
        style={{ position: "fixed", zIndex: "1", width: "100%", top: "0" }}
      >
        <Link to={"#"} style={{ textDecoration: "none" }}>
          <div
            className="collapseCard-body"
            style={{
              height: "38px",
              opacity: 1,
              transition: "height 0.3s ease 0s, opacity 0.3s ease 0s",
            }}
          >
            <div className="collapseCard-text">
              {" "}
              <span
                className=" text-white"
                style={{ fontFamily: "Poppins", fontSize: "15px !important" }}
              >
                Commission: {WebSitesettings?.adminPercentage50To400 || 0} % ◉ Referral: {WebSitesettings?.referPercentage || 0}% For All Games
              </span>
            </div>
          </div>
        </Link>

        <div
          className="collapseCard-header"
          style={{ left: "22px", transition: "left 0.3s ease 0s" }}
        ></div>
      </div>
    </div>
  );
};

export default Commission;
