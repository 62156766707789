import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Link,
} from "react-router-dom";
import "../css/landing.css";
import Header from "../Components/Header";
import Kyc from "./Kyc";
import Commission from "./Commission";


const Landing = () => {
  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const nodeMode = process.env.NODE_ENV;
  var baseUrl;
  if (nodeMode === "development") {
    baseUrl = beckendLocalApiUrl;
  } else {
    baseUrl = beckendLiveApiUrl;
  }

  let access_token = localStorage.getItem("token");
  access_token = localStorage.getItem("token");
  const [open, setOpen] = useState(false);
  const [userAllData, setUserAllData] = useState();
  const [WebSitesettings, setWebsiteSettings] = useState("");
  const fetchData = async () => {
    const response = await fetch(baseUrl + "settings/data");
    const data = await response.json();
    return setWebsiteSettings(data);
  };

  const role = async () => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    await axios
      .get(baseUrl + `me`, { headers })
      .then((res) => {
        setUserAllData(res.data);
      })
      .catch((e) => {
        if (e.response.status == 401) {
          localStorage.removeItem("token");
          localStorage.removeItem("token");
        }
      });
  };

  useEffect(() => {
    let access_token = localStorage.getItem("token");
    access_token = localStorage.getItem("token");
    if (!access_token) {
      // window.location.reload()
    }
    role();
    fetchData();
  }, []);

  const [post, setPost] = useState([]);
  const [postsecond, setPostsecond] = useState([]);

  useEffect(() => {
    axios.get(baseUrl + "getmessage").then((response) => {
      setPost(response.data);
      // console.log(setPost)
    });
    axios.get(baseUrl + "getmessage2").then((response) => {
      setPostsecond(response.data);
    });
  }, []);

  return (
    <>
      <div>
        <Commission />
        <div className="landing-header">
          <Header user={userAllData} />
        </div>
        {access_token ? (
          <div
            className="leftContainer"
            style={{ height: "100%", overflow: "hidden" }}
          >
            <div className="main-area mt-1">
              <div
                className="height-responsive"
                // style={
                //   userAllData
                //     ? { height: "110vh", overflow: "hidden" }
                //     : {
                //         height: "120vh",
                //         overflow: "hidden",
                //       }
                // }
              >
                <section className="games-section custom-game-section">
                  <div className="">
                    <div className="games-section-headline">
                      <div className="games-window d-block ">
                        {/* marquee text  section  */}
                        {postsecond.length > 0 ? (
                          <marquee
                            className="marqueebox-bg w-100"
                            style={{
                              borderRadius: "5px",
                              textAlign: "left",
                            }}
                            direction="left" 
                            scrollamount="4"
                          >
                            {postsecond.map((ele) => (
                              <p
                                className="message-text mb-0 p-3 text-dark "
                                style={{
                                  fontWeight: "900",
                                  color: "#664D03",
                                  fontSize: "16.4px",
                                  fontFamily:"Poppins"
                                }}
                              >
                                {ele.message}
                              </p>
                            ))}
                          </marquee>
                        ) : null}

                        {/* message section  */}

                        {post.length > 0 ? (
                          <div
                            className="messagebox-bg w-100"
                            style={{
                              borderRadius: "5px",
                              // margin: "8px",
                              textAlign: "left",
                            }}
                          >
                            <p>
                              {post.map((element) => (
                                <p
                                  className=" message-text mb-0 p-3"
                                  style={{
                                    fontWeight: "900",
                                    color: "#664D03",
                                    fontSize: "14.4px",
                                  }}
                                >
                                  {element.message}
                                </p>
                              ))}
                            </p>
                          </div>
                        ) : null}

                        {/* KYC section */}
                        <Kyc />

                        <div className="pt-3 d-flex justify-content-between mb-4 ">
                          <p
                            className="fs-2 text-dark fw-bold "
                            style={{
                              fontFamily: "Poppins",
                              letterSpacing: "2px",
                            }}
                          >
                            Games
                          </p>
                          <button
                            className="guide-btn d-flex "
                            type="button"
                            data-bs-toggle="offcanvas"
                            data-bs-target="#offcanvasBottom"
                            aria-controls="offcanvasBottom"
                            style={{ alignItems: "center" }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 16 16"
                              width="1em"
                              height="1em"
                              fill="currentColor"
                              class="me-2"
                            >
                              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"></path>
                              <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"></path>
                            </svg>
                            Guide
                          </button>
                        </div>
                        <div className="container">
                          <div className="row">
                          <div className="col-6  p-0">
                            <Link
                              className="gameCard-container  position-relative"
                              to={`/Homepage/Ludo%20Classics`}
                              
                            >
                              <picture className="">
                                <img
                                  className="w-100"
                                  style={{ borderRadius: "10px" }}
                                  src={
                                    process.env.PUBLIC_URL +
                                    "/Images/LandingPage_img/ludo_classic.6803ea97d59b498ccf57.png"
                                  }
                                  alt=""
                                />
                                <p className="liveOrCommingSoon text-white font-weight-bold position-absolute rounded-pill bg-success p-1 px-2" style={{  zIndex: 1 }}>Live</p>
                              </picture>
                            </Link>
                          </div>

                          <div className="col-6  p-0 ">
                           
                            <Link
                              className="gameCard-container position-relative"
                              to={`#`}
                            >
                              <picture className="">
                                <img
                                  className="w-100"
                                  style={{ borderRadius: "10px" }}
                                  src={
                                    process.env.PUBLIC_URL +
                                    "/Images/LandingPage_img/ludo_popular.65c966c4beccbb2a44b2.png"
                                  }
                                  alt=""
                                />
                                 <p className="liveOrCommingSoon text-black font-weight-bold position-absolute rounded-pill bg-white p-1" style={{  zIndex: 1 }}>Coming Soon</p>
                              </picture>
                            </Link>
                          </div>

                          <div className="col-6  p-0 ">
                            <Link
                              className="gameCard-container position-relative"
                              to={`#`}
                            >
                              <picture className="">
                                <img
                                  className="w-100"
                                  style={{ borderRadius: "10px" }}
                                  src={
                                    process.env.PUBLIC_URL +
                                    "/Images/LandingPage_img/ludo_snk.d0c15c4549e469857eeb.png"
                                  }
                                  alt=""
                                />
                                 <p className="liveOrCommingSoon text-black font-weight-bold position-absolute rounded-pill bg-white p-1" style={{  zIndex: 1 }}>Coming Soon</p>
                              </picture>
                            </Link>
                          </div>

                          <div className="col-6  text-center p-0">
                            
                            <Link
                              className="gameCard-container position-relative"
                              to={`#`}
                            >
                              <picture className="">
                                <img
                                  className="w-100"
                                  style={{ borderRadius: "10px" }}
                                  src={
                                    process.env.PUBLIC_URL +
                                    "/Images/LandingPage_img/ludo_rummy.c8f10fb97f30572bb265.png"
                                  }
                                  alt=""
                                />
                                 <p className="liveOrCommingSoon text-black font-weight-bold position-absolute rounded-pill bg-white p-1" style={{  zIndex: 1 }}>Coming Soon</p>
                              </picture>
                            </Link>
                          </div>
                          
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>

              <section className="footer pt-4">
                <div
                  className="position-fixed d-flex align-items-end justify-content-end hstack gap-2"
                  style={{
                    alignItems: "center",
                    zIndex: "10",
                    bottom: "60px",
                    left: "15px",
                    right: "20px",
                    maxWidth: "440px",
                  }}
                >
                  <Link
                    className=" d-flex shadow rounded-circle align-items-center justify-content-center"
                    to="/contact-us"
                    style={{ height: "60px", width: "60px" }}
                  >
                    <img
                      style={{ height: "35px", width: "35px" }}
                      src={
                        process.env.PUBLIC_URL +
                        "/Images/LandingPage_img/liveChat.webp"
                      }
                      alt=""
                    />
                  </Link>
                </div>
              </section>
            </div>
          </div>
        ) : (
          <div className="leftContainer leftcontainer-mobile-fixed  ">
            <div className="main-area">
              <div className="collapseCard-container  p-0">
                <div className="row">
                  <div className="col-lg-12 p-0">
                    <div className="collapseCard bg-danger p-0">
                      <div
                        className="collapseCard-header"
                        style={{
                          left: "22px",
                          transition: "left 0.3s ease 0s",
                        }}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="height-overflow border">
                <section className="games-section pt-2">
                  <div className=" rounded-3  ">
                    <div className="games-section-headline pb-1 ">
                      <div className="games-window d-block text-center">
                         {/* marquee text  section  */}
                         {postsecond.length > 0 ? (
                          <marquee
                            className="marqueebox-bg w-100"
                            style={{
                              borderRadius: "5px",
                              textAlign: "left",
                            }}
                            direction="left" 
                            scrollamount="4"
                          >
                            {postsecond.map((ele) => (
                              <p
                                className="message-text mb-0 p-3 text-dark "
                                style={{
                                  fontWeight: "900",
                                  color: "#664D03",
                                  fontSize: "16.4px",
                                  fontFamily:"Poppins"
                                }}
                              >
                                {ele.message}
                              </p>
                            ))}
                          </marquee>
                        ) : null}
                        <Link
                          className="gameCard-container "
                          to={`/Homepage/Ludo%20Classics`}
                        >
                          <picture className="">
                            <img
                              className="w-100 resposive-height-img"
                              src={
                                process.env.PUBLIC_URL +
                                "/Images/LandingPage_img/ludofrontimg.jpg"
                              }
                              alt=""
                            />
                          </picture>
                        </Link>
                      </div>
                    </div>
                  </div>
                </section>
                <section className="footer">
                  <div>
                    <p>
                      This Game involves an element of financial risk and may be
                      addictive please play responsibly and at your own risk .
                    </p>
                  </div>
                </section>

                <section className="footer">
                  <div
                    className="position-fixed d-flex align-items-center justify-content-center hstack gap-2"
                    style={{
                      alignItems: "center",
                      zIndex: "10",
                      bottom: "5px",
                      left: "15px",
                      right: "20px",
                      maxWidth: "440px",
                      bottom: "29px",
                    }}
                  >
                    <div className="d-grid flex-grow-1">
                      <Link to="/login">
                        <button className="playnow-btn btn-lg fw-semibold">
                          {" "}
                          Play Now
                        </button>
                      </Link>
                    </div>
                    <Link
                      className="bg-primary shadow-lg rounded-circle d-flex align-items-center justify-content-center text-white"
                      to="/support"
                      style={{ height: "60px", width: "60px" }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 16 16"
                        width="30"
                        height="30"
                        fill="currentColor"
                      >
                        <path d="M8 1a5 5 0 0 0-5 5v1h1a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V6a6 6 0 1 1 12 0v6a2.5 2.5 0 0 1-2.5 2.5H9.366a1 1 0 0 1-.866.5h-1a1 1 0 1 1 0-2h1a1 1 0 0 1 .866.5H11.5A1.5 1.5 0 0 0 13 12h-1a1 1 0 0 1-1-1V8a1 1 0 0 1 1-1h1V6a5 5 0 0 0-5-5z"></path>
                      </svg>
                    </Link>
                  </div>
                </section>
              </div>
            </div>
          </div>
        )}
        
      </div>
    </>
  );
};

export default Landing;
