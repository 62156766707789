import React from "react";
import "../css/History.css";
import Gamehistory from "./Gamehistory";
import Withdrawal  from "./Withdrawal";
import Referralhis from "./Referralhis";
import Bonus from "./Bonus";
import Penalty from "./Penalty";
import Transactionhistory from "./Transactionhistory";
import Deposit from "./Deposit";

const History = () => {
  return (
    <div
      className="leftContainer text-center"
      style={{ minHeight: "100vh", height: "100%", paddingTop: "55px" }}
    >
      <div className="pt-2 mt-3 ">
        <div className="p-3 pb-3">
          <ul
            className="nav nav-pills mb-0 pb-4 overflow-responsive  history-tab justify-content-between d-flex flex-nowrap "
            id="pills-tab"
            role="tablist"
          >
            <li className="nav-item tabs-responsive " role="presentation">
              <button
                className="nav-link active rounded-4 history-tabs "
                id="pills-all-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-all"
                type="button"
                role="tab"
                aria-controls="pills-all"
                aria-selected="true"
              >
                All
              </button>
            </li>
            <li className="nav-item tabs-responsive " role="presentation">
              <button
                className="nav-link rounded-4 history-tabs"
                id="pills-game-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-game"
                type="button"
                role="tab"
                aria-controls="pills-game"
                aria-selected="false"
              >
                Game
              </button>
            </li>
            <li className="nav-item tabs-responsive " role="presentation">
              <button
                className="nav-link rounded-4 history-tabs"
                id="pills-withdrawal-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-withdrawal"
                type="button"
                role="tab"
                aria-controls="pills-withdrawal"
                aria-selected="false"
              >
                Withdrawal 
              </button>
            </li>
            <li className="nav-item tabs-responsive " role="presentation">
              <button
                className="nav-link rounded-4 history-tabs"
                id="pills-deposit-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-deposit"
                type="button"
                role="tab"
                aria-controls="pills-deposit"
                aria-selected="false"
              >
                Deposit
              </button>
            </li>

            <li className="nav-item tabs-responsive " role="presentation">
              <button
                className="nav-link rounded-4 history-tabs"
                id="pills-referral-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-referral"
                type="button"
                role="tab"
                aria-controls="pills-referral"
                aria-selected="false"
              >
                Referral
              </button>
            </li>
            <li className="nav-item tabs-responsive " role="presentation">
              <button
                className="nav-link rounded-4 history-tabs"
                id="pills-penalty-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-penalty"
                type="button"
                role="tab"
                aria-controls="pills-penalty"
                aria-selected="false"
              >
                Penalty
              </button>
            </li>
            <li className="nav-item tabs-responsive " role="presentation">
              <button
                className="nav-link rounded-4 history-tabs "
                id="pills-bonus-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-bonus"
                type="button"
                role="tab"
                aria-controls="pills-bonus"
                aria-selected="false"
              >
                Bonus
              </button>
            </li>
          </ul>
          <div className="tab-content" id="pills-tabContent ">
            <div
              className="tab-pane fade show active"
              id="pills-all"
              role="tabpanel"
              aria-labelledby="pills-all-tab"
              tabindex="0"
            >
              <Transactionhistory  />
            </div>
            <div
              className="tab-pane fade"
              id="pills-game"
              role="tabpanel"
              aria-labelledby="pills-game-tab"
              tabindex="0"
            >
              <Gamehistory showRoomCodeSearch={true} />
            </div>
            <div
              className="tab-pane fade"
              id="pills-deposit"
              role="tabpanel"
              aria-labelledby="pills-deposit-tab"
              tabindex="0"
            >
              <Deposit />
            </div>
            <div
              className="tab-pane fade"
              id="pills-withdrawal"
              role="tabpanel"
              aria-labelledby="pills-withdrawal-tab"
              tabindex="0"
            >
              <Withdrawal  />
            </div>
            <div
              className="tab-pane fade"
              id="pills-referral"
              role="tabpanel"
              aria-labelledby="pills-referral-tab"
              tabindex="0"
            >
              <Referralhis showDefaultImage={true} />
            </div>
            <div
              className="tab-pane fade"
              id="pills-penalty"
              role="tabpanel"
              aria-labelledby="pills-penalty-tab"
              tabindex="0"
            >
              <Penalty />
            </div>
            <div
              className="tab-pane fade"
              id="pills-bonus"
              role="tabpanel"
              aria-labelledby="pills-bonus-tab"
              tabindex="0"
            >
              <Bonus />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default History;
