import React, { useEffect, useRef, useState } from "react";
import "../css/layout.css";
import "../css/landing.css";
import css from "../Modulecss/Home.module.css";
import axios from "axios";
import { Link, NavLink, useLocation } from "react-router-dom";
import Swal from "sweetalert2";
//import Rightcontainer from "../Components/Rightcontainer";
import Youtube from "../../assets/images/youtube.png";

import BetCard from "./BetCard";
import RunningCard from "./RunningCard";
import Header from "../Components/Header";
import Commission from "./Commission";
import CrowdCard from "./crowd-card";
import RunningBattleCrowd from "./running-battle-crowd";

// const STATIC_BET_AMOUNTS = [
//   50, 50, 350, 100, 3000, 50, 150, 900, 1500, 50, 100, 250, 500, 100, 50, 2500,
//   700, 50, 350, 1100, 650, 100, 50, 5000, 450, 250, 600, 1050, 1500, 100, 150,
//   350, 50, 550, 1100, 1200, 150, 1600, 450, 50, 50, 2000, 300, 2500, 100, 750,
//   350, 3000, 50, 1650, 11500, 850, 400, 500, 600, 50, 200, 7000, 10000, 1350,
//   100, 300, 950,
// ];

let STATIC_BET_AMOUNTS = [
  50, 50, 350, 100, 3000, 50, 150, 900, 1500, 50, 100, 250, 500, 100, 50, 2500,
  700, 50, 350, 1100, 650, 100, 50, 5000, 450, 250, 600, 1050, 1500, 100, 150,
  350, 50, 550, 1100, 1200, 150, 1600, 450, 50, 50, 2000, 300, 2500, 100, 750,
  350, 3000, 50, 1650, 850, 400, 500, 600, 50, 200, 1350, 100, 300, 950,
];

// Define the threshold amount
const threshold = 25000;

// Divide the array into two parts based on the threshold
const belowThreshold = STATIC_BET_AMOUNTS.filter(
  (amount) => amount < threshold
);
const aboveThreshold = STATIC_BET_AMOUNTS.filter(
  (amount) => amount >= threshold
);

// Print or use the results as needed
console.log("Below threshold:", belowThreshold);
console.log("Above threshold:", aboveThreshold);

//import { Alert } from "@mui/material";

export default function Homepage({ walletUpdate }) {
  //const history = useHistory();
  let userID = useRef();
  const isMounted = useRef(true);

  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const websocketURL = process.env.REACT_APP_SOCKET_API;
  const nodeMode = process.env.NODE_ENV;
  if (nodeMode === "development") {
    var baseUrl = beckendLocalApiUrl;
  } else {
    baseUrl = beckendLiveApiUrl;
  }
  /// user details start

  const [user, setUser] = useState();
  const [created, setCreated] = useState([]);
  const [socket, setSocket] = useState();
  const [staticBets, setStaticBets] = useState([]);
  const [staticRunningBets, setRunningStaticBets] = useState([]);

  const [userAllData, setUserAllData] = useState();
  const [randomName, setRandomName] = useState();

  const [isMode, setMode] = useState(
    localStorage.getItem("Mode") || "liteMode"
  );

  STATIC_BET_AMOUNTS = isMode === "liteMode" ? belowThreshold : aboveThreshold;

  useEffect(() => {
    setStaticBets(generateRandomBetSet(5)); // Initialize with 10 random bets
    setRunningStaticBets(generateRandomBetSet(5)); // Initialize with 10 random bets

    const interval = setInterval(() => {
      setStaticBets((prevStaticBets) => {
        const randomAmountIndex = Math.floor(
          Math.random() * STATIC_BET_AMOUNTS.length
        );

        // Decide whether to add or remove a card
        const addCard = Math.random() < 0.5; // 50% chance to add a card

        if (addCard) {
          // Add a new random bet to the beginning of the array
          return [
            generateRandomBet(STATIC_BET_AMOUNTS[randomAmountIndex]),
            ...prevStaticBets.slice(0, 5), // Slice to keep only 9 cards
          ];
        } else {
          // Remove the last bet from the array, if it's not empty
          if (prevStaticBets.length > 0) {
            return prevStaticBets.slice(0, 5); // Slice to keep only 9 cards
          } else {
            return prevStaticBets; // Return the unchanged state
          }
        }
      });

      setRunningStaticBets((prevRunningStaticBets) => {
        const randomAmountIndex = Math.floor(
          Math.random() * STATIC_BET_AMOUNTS.length
        );

        // Decide whether to add or remove a card
        const addCard = Math.random() < 0.5; // 50% chance to add a card

        if (addCard) {
          // Add a new random bet to the beginning of the array
          return [
            generateRandomBet(STATIC_BET_AMOUNTS[randomAmountIndex]),
            ...prevRunningStaticBets.slice(0, 5), // Slice to keep only 9 cards
          ];
        } else {
          // Remove the last bet from the array, if it's not empty
          if (prevRunningStaticBets.length > 0) {
            return prevRunningStaticBets.slice(0, 5); // Slice to keep only 9 cards
          } else {
            console.log("static bet length is zero");
            return prevRunningStaticBets; // Return the unchanged state
          }
        }
      });
    }, 1500);

    return () => clearInterval(interval);
  }, [randomName]);

  useState(() => {
    console.log("static bet changed");
  }, [staticBets]);

  const role = async () => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    await axios
      .get(baseUrl + `me`, { headers })
      .then((res) => {
        setUser(res.data._id);
        setUserAllData(res.data);
        userID.current = res.data._id;
        setMount(true);
      })
      .catch((e) => {
        if (e.response.status === 401) {
          localStorage.removeItem("token");
          localStorage.removeItem("token");
          window.location.reload();
          setTimeout(() => {
            //  history.push("/login")
          }, 500);
        }
        if (e.response.status === 400 || e.response.status === 429) {
          Swal.fire({
            title: "Please refresh!",
            icon: "warning",
            confirmButtonText: "OK",
          });
        }
      });
  };

  /// user details end

  const [game_type, setGame_type] = useState(
    useLocation().pathname.split("/")[2]
  );
  const [gameAmount, setGame_Ammount] = useState("");
  const [loading, setLoading] = useState(false);

  //   console.log(game_type);

  const ChallengeCreate = (e) => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    if (!gameAmount) {
      Swal.fire({
        title: "Game Amount",
        text: "Please enter game amount.",
        icon: "error",
        confirmButtonText: "OK",
      });
      return;
    } else if (isMode === "liteMode" && gameAmount > 25000) {
      Swal.fire({
        title: "Game Amount",
        text: "Please enter game amount 50 to 25000.",
        icon: "error",
        confirmButtonText: "OK",
      });
      return;
    }

    setLoading(true);
    axios
      .post(
        baseUrl + `challange/create`,
        {
          gameAmount,
          gameType: game_type,
        },
        { headers }
      )
      .then((res) => {
        setLoading(false);
        if (
          res.data.msg ===
          "Your withdrawal request is pending you can't create new battle now"
        ) {
          Swal.fire({
            title:
              "Your withdrawal request is pending you can't create new battle now",
            icon: "warning",
            confirmButtonText: "OK",
          });
        }
        if (res.data.msg === "you can not create same amount challenge.") {
          Swal.fire({
            title: "you can not create same amount challenge.",
            icon: "warning",
            confirmButtonText: "OK",
          });
        } else if (res.data.msg === "you have already enrolled") {
          Swal.fire({
            title: "You have already enrolled",
            icon: "warning",
            confirmButtonText: "OK",
          });
        } else if (res.data.msg === "You can set maximum 2 battle.") {
          Swal.fire({
            title: "You can set maximum 2 battle.",
            icon: "warning",
            confirmButtonText: "OK",
          });
        } else if (res.data.msg === "Insufficient balance") {
          Swal.fire({
            title: "Insufficient balance",
            icon: "warning",
            confirmButtonText: "OK",
          });
        } else if (
          res.data.msg ===
          "Game amount should be Greater then 50 and less then 25000"
        ) {
          Swal.fire({
            title: "Game amount should be Greater then 50 and less then 25000",
            icon: "warning",
            confirmButtonText: "OK",
          });
        } else if (res.data.msg === "Set Battle in denomination of 50") {
          Swal.fire({
            title: "Set Battle in denomination of 50",
            icon: "warning",
            confirmButtonText: "OK",
          });
        } else if (res.data.msg === "Technical Issue, Try after an hour!") {
          Swal.fire({
            title: "Technical Issue, Try after an hour!",
            icon: "warning",
            confirmButtonText: "OK",
          });
        } else {
          // Allgames();
          socket.emit("gameCreated");
        }
      })
      .catch((e) => {
        setLoading(false);
        if (e.response.status === 401) {
          localStorage.removeItem("token");
          localStorage.removeItem("token");
          window.location.reload();
          setTimeout(() => {
            //  history.push("/login")
          }, 500);
        }
        if (e.response.status === 400 || e.response.status === 429) {
          Swal.fire({
            title: "Please refresh!",
            icon: "warning",
            confirmButtonText: "OK",
          });
        }
        console.log(e);
      });
    setGame_Ammount("");
  };

  const [allgame, setallgame] = useState([]);
  const [mount, setMount] = useState(false);
  //const [ALL, setALL] = useState();
  const [runningGames, setRunningGames] = useState();
  const [ownRunning, setOwnRunning] = useState([]);
  const Allgames = async () => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };

    axios
      .get(baseUrl + `challange/all`, { headers })
      .then((res) => {
        if (res.data.message == "Game challenges not found") {
          return;
        }
        let owenedCreated = [],
          remainingGame = [];
        res.data.forEach(function (ele) {
          if (
            ele.createdBy._id === user &&
            (ele.status === "new" || ele.status === "requested")
          ) {
            owenedCreated.push(ele);
          } else {
            remainingGame.push(ele);
          }
        });
        // console.log('challenge API response', res.data)
        setCreated(owenedCreated);
        setallgame(remainingGame);
      })
      .catch((e) => {
        if (e.response.status === 401) {
          localStorage.removeItem("token");
          localStorage.removeItem("token");
          window.location.reload();
          setTimeout(() => {
            //  history.push("/login")
          }, 500);
        }
        if (e.response.status === 400 || e.response.status === 429) {
          Swal.fire({
            title: "Please refresh!",
            icon: "warning",
            confirmButtonText: "OK",
          });
        }
      });
  };

  const runningGame = async () => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };

    axios
      .get(baseUrl + `challange/running/all`, { headers })
      .then((res) => {
        if (res.data.message == "Game challenges not found") {
          return;
        }
        let owenedRunning = [],
          remainingRunning = [];
        res.data.forEach(function (ele) {
          if (ele.createdBy && ele.acceptedBy)
            if (
              ele.createdBy._id === userID.current ||
              ele.acceptedBy._id === userID.current
            ) {
              owenedRunning.push(ele);
            } else {
              remainingRunning.push(ele);
            }
        });
        setOwnRunning(owenedRunning);
        setRunningGames(remainingRunning);
      })
      .catch((e) => {
        console.log("errror", e);
        if (e.response.status === 401) {
          localStorage.removeItem("token");
          localStorage.removeItem("token");
          window.location.reload();
          //    setTimeout(() => {
          // //  history.push("/login")
          // }, 500);
        }
        if (e.response.status === 400 || e.response.status === 429) {
          Swal.fire({
            title: "Please refresh!",
            icon: "warning",
            confirmButtonText: "OK",
          });
        }
      });
  };

  const [WebSitesettings, setWebsiteSettings] = useState("");

  const fetchData = async () => {
    console.log("fetch data");
    const response = await fetch(baseUrl + "settings/data");
    const data = await response.json();
    console.log(data);
    return setWebsiteSettings(data);
  };

  const fetchRandomName = async () => {
    console.log("fetch data");
    const response = await fetch(baseUrl + "randomUserName");
    const data = await response.json();
    console.log(data);
    return setRandomName(data);
  };

  useEffect(() => {
    fetchData();
    fetchRandomName();
  }, []);

  function generateRandomString(length) {
    let result = "";
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  function winamountFun(gameAmount) {
    let profit = null;
    if (gameAmount >= 50 && gameAmount <= 400) {
      profit =
        (gameAmount * parseInt(WebSitesettings?.adminPercentage50To400 || 5)) /
        100;
    } else if (gameAmount > 400) {
      profit =
        (gameAmount * parseInt(WebSitesettings?.adminPercentage400To || 5)) /
        100;
    }
    return gameAmount - profit;
  }

  function generateRandomBet(amount) {
    const winAmount = winamountFun(amount);
    return { amount, winAmount, playerName: generateRandomString(6) };
  }

  function generateSubArray(mainArray, subArrayLength) {
    // Check if the main array is shorter than the desired subarray length
    if (mainArray.length < subArrayLength) {
      throw new Error("Main array length is shorter than subarray length.");
    }

    // Generate random start index within valid range
    const startIndex = Math.floor(
      Math.random() * (mainArray.length - subArrayLength + 1)
    );

    // Calculate end index within valid range
    const endIndex = startIndex + subArrayLength;

    // Generate the subarray
    const subArray = mainArray.slice(startIndex, endIndex);

    return subArray;
  }

  function generateRandomBetSet(setLength) {
    const amounts = generateSubArray(STATIC_BET_AMOUNTS, setLength);
    const bets = amounts.map((amount) => {
      return generateRandomBet(amount);
    });
    return bets;
  }

  useEffect(() => {
    WebSocket.prototype.emit = function (event, data) {
      if (this.readyState === WebSocket.OPEN)
        this.send(JSON.stringify({ event, data }));
    };
    WebSocket.prototype.listen = function (eventName, callback) {
      this._socketListeners = this._socketListeners || {};
      this._socketListeners[eventName] = callback;
    };

    let socket = new WebSocket(websocketURL);

    //let socket = new WebSocket("ws://192.168.29.119:5001/server");
    function openFunc() {
      socket.onopen = () => {
        console.log("websocket is connected 👍");
        setSocket(socket);
        socket.pingTimeout = setTimeout(() => {
          socket.close();
          setSocket(undefined);
        }, 30000 + 1000);
      };
    }

    function listenFunc() {
      socket.onmessage = function (e) {
        try {
          const { event, data } = JSON.parse(e.data);
          socket._socketListeners[event](data);
        } catch (error) {
          console.log(error);
        }
      };

      socket.listen("ping", (data) => {
        socket.emit("pong", 2);
        clearTimeout(socket.pingTimeout);
        socket.pingTimeout = setTimeout(() => {
          console.log("ping terminate works 🏩");
          socket.close();
          setSocket(undefined);
        }, 30000 + 1000);
      });

      socket.listen("recieveGame", (data) => {
        console.log("receiveGameData", data);
        let owenedCreated = [],
          remainingGame = [];
        data.forEach(function (ele) {
          if (ele.createdBy)
            if (
              ele.createdBy._id === userID.current &&
              (ele.status === "new" || ele.status === "requested")
            ) {
              owenedCreated.push(ele);
            } else {
              remainingGame.push(ele);
            }
        });
        // console.log('own',owenedCreated,'remiain',remainingGame);
        console.log("ownedCreated", owenedCreated);
        setCreated(owenedCreated);
        setallgame(remainingGame);
      });

      socket.listen("updateRunning", (data) => {
        let owenedCreated = [],
          remainingGame = [];
        data.forEach(function (ele) {
          if (ele.createdBy)
            if (
              ele.createdBy._id == userID.current &&
              (ele.status == "new" || ele.status == "requested")
            ) {
              owenedCreated.push(ele);
            } else {
              remainingGame.push(ele);
            }
        });
        setCreated(owenedCreated);
        setallgame(remainingGame);
        walletUpdate();
      });

      socket.listen("acceptor_seen", (data) => {
        let owenedCreated = [],
          remainingGame = [];
        data.openBattle.forEach(function (ele) {
          if (ele.createdBy)
            if (
              ele.createdBy._id == userID.current &&
              (ele.status == "new" || ele.status == "requested")
            ) {
              owenedCreated.push(ele);
            } else {
              remainingGame.push(ele);
            }
        });
        setCreated(owenedCreated);
        setallgame(remainingGame);
        let owenedRunning = [],
          remainingRunning = [];
        data.runningBattle.forEach(function (ele) {
          if (ele.createdBy && ele.acceptedBy)
            if (
              ele.createdBy._id == userID.current ||
              ele.acceptedBy._id == userID.current
            ) {
              owenedRunning.push(ele);
            } else {
              remainingRunning.push(ele);
            }
        });
        setOwnRunning(owenedRunning);
        setRunningGames(remainingRunning);
        walletUpdate();
      });

      socket.listen("resultUpdateReq", (data) => {
        let owenedRunning = [],
          remainingRunning = [];
        data.forEach(function (ele) {
          if (ele.createdBy && ele.acceptedBy)
            if (
              ele.createdBy._id == userID.current ||
              ele.acceptedBy._id == userID.current
            ) {
              owenedRunning.push(ele);
            } else {
              remainingRunning.push(ele);
            }
        });
        setOwnRunning(owenedRunning);
        setRunningGames(remainingRunning);
        walletUpdate();
      });

      socket.listen("startAcepptor", (data) => {
        let owenedCreated = [],
          remainingGame = [];
        data.forEach(function (ele) {
          if (ele.createdBy)
            if (
              ele.createdBy._id == userID.current &&
              (ele.status == "new" || ele.status == "requested")
            ) {
              owenedCreated.push(ele);
            } else {
              remainingGame.push(ele);
            }
        });
        setCreated(owenedCreated);
        setallgame(remainingGame);
        walletUpdate();
      });

      socket.listen("challengeAccepted", (data) => {
        let owenedCreated = [],
          remainingGame = [];
        data.forEach(function (ele) {
          if (ele.createdBy)
            if (
              ele.createdBy._id == userID.current &&
              (ele.status == "new" || ele.status == "requested")
            ) {
              owenedCreated.push(ele);
            } else {
              remainingGame.push(ele);
            }
        });
        setCreated(owenedCreated);
        setallgame(remainingGame);
      });

      socket.listen("updateReject", (data) => {
        let owenedCreated = [],
          remainingGame = [];
        data.forEach(function (ele) {
          if (ele.createdBy)
            if (
              ele.createdBy._id == userID.current &&
              (ele.status == "new" || ele.status == "requested")
            ) {
              owenedCreated.push(ele);
            } else {
              remainingGame.push(ele);
            }
        });
        setCreated(owenedCreated);
        setallgame(remainingGame);
      });

      socket.listen("ongoingChallenge", (data) => {
        let owenedCreated = [],
          remainingGame = [];
        data.openBattle.forEach(function (ele) {
          if (ele.createdBy)
            if (
              ele.createdBy._id == userID.current &&
              (ele.status == "new" || ele.status == "requested")
            ) {
              owenedCreated.push(ele);
            } else {
              remainingGame.push(ele);
            }
        });
        setCreated(owenedCreated);
        setallgame(remainingGame);
        let owenedRunning = [],
          remainingRunning = [];
        data.runningBattle.forEach(function (ele) {
          if (ele.createdBy && ele.acceptedBy)
            if (
              ele.createdBy._id == userID.current ||
              ele.acceptedBy._id == userID.current
            ) {
              owenedRunning.push(ele);
            } else {
              remainingRunning.push(ele);
            }
        });
        setOwnRunning(owenedRunning);
        setRunningGames(remainingRunning);
      });

      socket.listen("updateDelete", (data) => {
        let owenedCreated = [],
          remainingGame = [];
        data.forEach(function (ele) {
          if (ele.createdBy)
            if (
              ele.createdBy._id == userID.current &&
              (ele.status == "new" || ele.status == "requested")
            ) {
              owenedCreated.push(ele);
            } else {
              remainingGame.push(ele);
            }
        });
        setCreated(owenedCreated);
        setallgame(remainingGame);
      });
    }
    function closeFunc() {
      socket.onclose = () => {
        console.log("socket disconnected wow 😡");

        if (isMounted.current) {
          clearTimeout(socket.pingTimeout);
          setSocket(undefined);

          let socket = new WebSocket(websocketURL);

          //socket = new WebSocket("ws://192.168.29.119:5001/server");
          openFunc();
          listenFunc();
          closeFunc();
        }
      };
    }
    openFunc();
    listenFunc();
    closeFunc();

    return () => {
      isMounted.current = false;
      clearTimeout(socket.pingTimeout);
      setSocket(undefined);
      socket.close();
    };
  }, []);

  useEffect(() => {
    let access_token = localStorage.getItem("token");
    access_token = localStorage.getItem("token");
    if (!access_token) {
      window.location.reload();
      setTimeout(() => {
        //  history.push("/login")
      }, 500);
    }
    role();
    if (mount) {
      Allgames();
      runningGame();
    }
  }, [mount]);
  //accept Challange

  const AcceptChallang = (id) => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    axios
      .put(
        baseUrl + `challange/accept/${id}`,
        {
          acceptedBy: headers,
          acceptorByCreatorAt: Date.now(),
        },
        {
          headers,
        }
      )
      .then((res) => {
        if (res.data.msg === "you have already enrolled") {
          Swal.fire({
            title: "You have already enrolled",
            icon: "warning",
            confirmButtonText: "OK",
          });
        }
        if (res.data.msg === "Insufficient balance") {
          Swal.fire({
            title: "Insufficient balance",
            icon: "warning",
            confirmButtonText: "OK",
          });
        } else {
          Allgames(res.data);
          socket.emit("acceptGame");
        }
      })
      .catch((e) => {
        console.log(e);
        if (e.response.status == 401) {
          localStorage.removeItem("token");
          localStorage.removeItem("token");
          window.location.reload();
          setTimeout(() => {
            //  history.push("/login")
          }, 500);
        }
        if (e.response.status == 400 || e.response.status == 429) {
          Swal.fire({
            title: "Please refresh!",
            icon: "warning",
            confirmButtonText: "OK",
          });
        }
      });
  };

  //reject Game
  const RejectGame = (id) => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };

    axios
      .put(
        baseUrl + `challange/reject/${id}`,
        {
          acceptedBy: null,
          status: "new",
          acceptorByCreatorAt: null,
        },
        { headers }
      )
      .then((res) => {
        socket.emit("gameRejected");
      })
      .catch((e) => {
        console.log(e);
        if (e.response.status == 401) {
          localStorage.removeItem("token");
          localStorage.removeItem("token");
          window.location.reload();
          setTimeout(() => {
            //  history.push("/login")
          }, 500);
        }
        if (e.response.status == 400 || e.response.status == 429) {
          Swal.fire({
            title: "Please refresh!",
            icon: "warning",
            confirmButtonText: "OK",
          });
        }
      });
  };

  //delete
  const deleteChallenge = (_id) => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };

    axios
      .delete(baseUrl + `challange/delete/${_id}`, { headers })
      .then((res) => {
        socket.emit("deleteGame", _id);
      })
      .catch((e) => {
        //console.log(e);
        if (e.response.status == 401) {
          localStorage.removeItem("token");
          localStorage.removeItem("token");
          window.location.reload();
          setTimeout(() => {
            //  history.push("/login")
          }, 500);
        }
        if (e.response.status == 400 || e.response.status == 429) {
          Swal.fire({
            title: "Please refresh!",
            icon: "warning",
            confirmButtonText: "OK",
          });
        }
      });
  };

  ///challange/running/update/

  const updateChallenge = (_id) => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };

    axios
      .put(
        baseUrl + `challange/running/update/${_id}`,
        {
          acceptorSeen: true,
        },
        { headers }
      )
      .then((res) => {
        socket.emit("game_seen");
      })
      .catch((e) => {
        if (e.response.status == 401) {
          localStorage.removeItem("token");
          localStorage.removeItem("token");
          window.location.reload();
          setTimeout(() => {
            //  history.push("/login")
          }, 500);
        }
        if (e.response.status == 400 || e.response.status == 429) {
          Swal.fire({
            title: "Please refresh!",
            icon: "warning",
            confirmButtonText: "OK",
          });
        }
        console.log(e);
      });
  };

  // const [roomCode, setRoomCode] = useState()

  const getPost = async (Id) => {
    if (
      game_type === "Ludo Classics" ||
      game_type === "Ludo 1 Goti" ||
      game_type === "Ludo Ulta"
    ) {
      socket.emit("roomCode", { game_id: Id, status: "running" });
    } else if (game_type === "Ludo Popular") {
      socket.emit("popularroomCode", { game_id: Id, status: "running" });
    }
  };
  const [postsecond, setPostsecond] = useState([]);

  useEffect(() => {
    axios.get(baseUrl + "getmessage2").then((response) => {
      setPostsecond(response.data);
    });
  }, []);

  useEffect(() => {
    if (randomName && randomName.length > 0) {
      randomName.forEach((item) => {
        setStaticBets(generateRandomBetSet(10));
      });
    }
  }, [randomName]);

  return (
    <>
      <div
        className="leftContainer "
        style={{ minHeight: "100vh", paddingTop: "60px" }}
      >
        <div className="mt-2 p-3">
          <div className={css.mainArea} style={{ minHeight: "100vh" }}>
            {/* marquee text  section  */}
            {postsecond.length > 0 ? (
              <marquee
                className="marqueebox-bg w-100"
                style={{
                  borderRadius: "5px",
                  textAlign: "left",
                }}
                direction="left"
                scrollamount="4"
              >
                {postsecond.map((ele) => (
                  <p
                    className="message-text mb-0 p-3 text-dark "
                    style={{
                      fontWeight: "900",
                      color: "#664D03",
                      fontSize: "16.4px",
                      fontFamily: "Poppins",
                    }}
                  >
                    {ele.message}
                  </p>
                ))}
              </marquee>
            ) : null}

            {/* <div className="card rounded-pill border mb-4">
            <div className="d-flex justify-content-between align-items-center">
            <div className="text-center ml-3">
              <label className="form-check-label font-weight-bold" htmlFor="themeSwitch" id="themeSwitchLabel">
                    {isMode === "liteMode" ? 'Lite Mode' : 'Rich Mode'}
              </label>
            </div>
            <div className="text-center">
              <label className="form-check-label font-weight-bold" htmlFor="themeSwitch" id="themeSwitchLabel">
                    {isMode === "liteMode" ? '50 to 550' : '550 to 25000'}
              </label>
            </div>
            <button onClick={() => toggleTheme(isMode === "richMode" ? 'liteMode' : 'richMode' )} className="btn btn-danger text-white rounded-pill">{isMode === "liteMode" ? '👉 Switch to Rich Mode' : '👉 Switch to Lite Mode' }</button>            
            </div>
          </div> */}

            {/* <div className="mb-4 mt-2">
            <div className="d-flex justify-content-between align-items-center">
                <button onClick={() => toggleTheme('liteMode')} className={`btn ${isMode === "liteMode" ? "btn-danger text-white" : "btn-secondary text-black"} py-2  w-100 mr-2`}>Lite Mode (50 to 550)</button>            
                <button onClick={() => toggleTheme("richMode")} className={`btn ${isMode === "liteMode" ? "btn-secondary text-black" : "btn-danger text-white"} py-2  w-100 mr-2`}>Rich Mode (550 to 25000)</button>            
            </div>
          </div> */}

            <div className="tab-content" id="pills-tabContent">
              {/* Lite mode content section */}

              <div
                className="tab-pane fade show active"
                id="pills-home"
                role="tabpanel"
                aria-labelledby="pills-home-tab"
                tabIndex="0"
              >
                <div
                  className=" d-flex my-2 w-100  rounded-2 "
                  style={{
                    alignItems: "center",
                    border: "1px solid #ccc",
                    paddingRight: "2px",
                    paddingTop: "1px",
                    paddingBottom: "1px",
                  }}
                >
                  <div className="w-100">
                    <input
                      style={{
                        width: "100%",
                        border: "0px",
                        background: "none",
                      }}
                      className={`${css.formControl} set-input`}
                      type="tel"
                      placeholder="Enter amount"
                      value={gameAmount}
                      onChange={(e) => setGame_Ammount(e.target.value)}
                    />
                  </div>

                  <div className="set ml-1 ">
                    {" "}
                    <button
                      className={`  position-static px-3 rounded-2 `}
                      style={{
                        margin: "20px !important",
                        backgroundColor: "#123441",
                        height: "40px",
                      }}
                      onClick={(e) => {
                        e.preventDefault();
                        ChallengeCreate();
                      }}
                    >
                      <span
                        style={{
                          color: "#fff",
                          fontSize: "15px",
                          fontWeight: "700",
                          fontFamily: "Poppins",
                          letterSpacing: "1px",
                        }}
                      >
                        {loading ? (
                          <>
                            {" "}
                            <span
                              class="spinner-border spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>
                          </>
                        ) : (
                          "Set"
                        )}
                      </span>
                    </button>
                  </div>
                </div>
                <div className={css.dividerX}></div>
                <div className=" py-3">
                  <div className="mb-3">
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/Images/Homepage/battleIcon.png"
                      }
                      alt=""
                      width="20px"
                    />
                    <span
                      style={{
                        fontSize: "20px",
                        color: "#fe3347",
                        fontFamily: "Poppins",
                      }}
                      className={`ml-2 ${css.gamesSectionTitle}`}
                    >
                      Open Battles
                    </span>
                    <span
                      className={`${css.gamesSectionHeadline} text-uppercase position-absolute mt-2 font-weight-bold`}
                      style={{ right: "1.5rem", color: "#00D25B" }}
                    >
                      <span style={{ fontSize: "13px" }}> Rules</span>
                      <NavLink to="/Rules">
                        <img
                          className="ml-2"
                          style={{ height: "20px", width: "20px" }}
                          src={
                            process.env.PUBLIC_URL + "/Images/Homepage/info.png"
                          }
                          alt=""
                        />
                      </NavLink>
                    </span>
                  </div>

                  {created &&
                    created?.map(
                      (allgame) =>
                        allgame.gameType == game_type && (
                          <BetCard
                            key={allgame._id}
                            allgame={allgame}
                            user={user}
                            deleteChallenge={deleteChallenge}
                            getPost={getPost}
                            RejectGame={RejectGame}
                            winamountFun={winamountFun}
                            AcceptChallang={AcceptChallang}
                            updateChallenge={updateChallenge}
                          />
                        )
                    )}
                  {allgame &&
                    allgame?.map(
                      (allgame) =>
                        (allgame.status == "new" ||
                          (allgame.status == "requested" &&
                            (user == allgame.createdBy._id ||
                              user == allgame.acceptedBy._id)) ||
                          (allgame.status == "running" &&
                            user == allgame.acceptedBy._id &&
                            allgame.acceptorSeen == false)) &&
                        allgame.gameType == game_type && (
                          <BetCard
                            key={allgame._id}
                            allgame={allgame}
                            user={user}
                            deleteChallenge={deleteChallenge}
                            getPost={getPost}
                            RejectGame={RejectGame}
                            winamountFun={winamountFun}
                            AcceptChallang={AcceptChallang}
                            updateChallenge={updateChallenge}
                          />
                        )
                    )}
                  {/* {staticBets &&
                    staticBets.map((bet, index) => (
                      <CrowdCard
                        key={index}
                        bet={bet}
                        onPlayClicked={() => {
                          setStaticBets((currentState) => {
                            const copyArray = JSON.parse(
                              JSON.stringify(currentState)
                            );
                            copyArray.splice(index, 1);
                            return copyArray;
                          });
                        }}
                      />
                    ))} */}
                </div>
                <div
                  style={{ background: "red", width: "100%", height: "3px" }}
                ></div>
                <div className=" py-3 mb-5">
                  <div className="mb-2">
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/Images/Homepage/battleIcon.png"
                      }
                      alt=""
                      width="20px"
                    />
                    <span
                      style={{
                        fontSize: "20px",
                        color: "red",
                        fontFamily: "Poppins",
                      }}
                      className={`ml-2 ${css.gamesSectionTitle}`}
                    >
                      Running Battles
                    </span>
                  </div>
                  {ownRunning &&
                    ownRunning.map((runnig) => {
                      const isUserAccepted = user === runnig.acceptedBy._id;
                      const isUserCreator = user === runnig.createdBy._id;
                      const isUserConflicted =
                        (isUserAccepted && runnig.status === "conflict") ||
                        (isUserCreator && runnig.status === "conflict");

                      if (user === runnig?.acceptedBy._id) {
                        console.log("in acceptor block");
                        if (
                          (runnig?.status === "running" &&
                            runnig?.acceptorSeen) ||
                          runnig.status === "pending" ||
                          runnig.status === "conflict" ||
                          isUserConflicted
                        ) {
                          console.log("running game", runnig);
                          if (runnig.gameType === game_type) {
                            return (
                              <RunningCard
                                key={runnig._id}
                                runnig={runnig}
                                user={user}
                                winamountFun={winamountFun}
                              />
                            );
                          }
                        }
                      } else {
                        if (
                          (runnig.status === "running" &&
                            user === runnig.createdBy._id) ||
                          runnig.status === "pending" ||
                          runnig.status === "conflict" ||
                          isUserConflicted
                        ) {
                          if (runnig.gameType === game_type) {
                            return (
                              <RunningCard
                                key={runnig._id}
                                runnig={runnig}
                                user={user}
                                winamountFun={winamountFun}
                              />
                            );
                          }
                        }
                      }
                    })}

                  {runningGames &&
                    runningGames?.map((runnig) => {
                      if (
                        (user == runnig.acceptedBy._id ||
                        user == runnig.createdBy._id
                          ? user == runnig.acceptedBy._id
                            ? (runnig.status === "running" &&
                                user == runnig.acceptedBy._id &&
                                runnig.acceptorSeen == true) ||
                              (runnig.status === "pending" &&
                                runnig.acceptorStatus == null)
                            : (runnig.status === "running" &&
                                user == runnig.createdBy._id) ||
                              (runnig.status === "pending" &&
                                runnig.creatorStatus == null)
                          : runnig.status === "running" ||
                            runnig.status === "pending") &&
                        runnig.gameType == game_type
                      )
                        return (
                          <RunningCard
                            key={runnig._id}
                            runnig={runnig}
                            user={user}
                            winamountFun={winamountFun}
                          />
                        );
                    })}
                  {staticRunningBets &&
                    staticRunningBets.map((bet, index) => (
                      <RunningBattleCrowd key={index} bet={bet} />
                    ))}
                </div>
              </div>

              {/* Rich mode content section*/}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
