import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import css from "../css/gamehis.module.css";
import ReactPaginate from "react-paginate";
import axios from "axios";
import chips_add from "../../assets/images/history_icons/chips-add.png";
import cancel from "../../assets/images/history_icons/cancel.png";
import "../css/Paymentinout.css";

const Deposit = () => {
  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const nodeMode = process.env.NODE_ENV;
  if (nodeMode === "development") {
    var baseUrl = beckendLocalApiUrl;
  } else {
    baseUrl = beckendLiveApiUrl;
  }
  //eslint-disable-next-line
  const [user, setUser] = useState();
  //use for pagination..
  let limit = 10000;
  const [pageNumber, setPageNumber] = useState(0);
  const [numberOfPages, setNumberOfPages] = useState(0);

  const role = async () => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    await axios.get(baseUrl + `me`, { headers }).then((res) => {
      setUser(res.data);

      //Allgames(res.data._id)
      Allgames(res.data._id, pageNumber, limit);
      // window.location.reload()
    });
  };

  const handlePageClick = async (data) => {
    let currentPage = data.selected + 1;
    setPageNumber(currentPage);
    // scroll to the top
    //window.scrollTo(0, 0)
  };

  const dateFormat = (e) => {
    const date = new Date(e);
    const monthNames = [
      "Jan", "Feb", "Mar",
      "Apr", "May", "Jun", "Jul",
      "Aug", "Sep", "Oct",
      "Nov", "Dec"
    ];
    const day = date.getDate();
    const monthIndex = date.getMonth();
    let hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? 'pm' : 'am';
  hours = hours % 12;
  hours = hours ? hours : 12; // handle midnight
    const formattedDate = `${day} ${monthNames[monthIndex]} , ${hours}:${minutes < 10 ? '0' : ''}${minutes} ${ampm}`;
  
    return formattedDate;
  };
  
  const dateFormat2 = (e) => {
    const date = new Date(e);
    const newDate = date.toLocaleString("default", {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      hour12: true,
      minute: "numeric",
      second: "numeric",
    });
    return newDate;
  };

  const [cardData, setGame] = useState();

  const Allgames = async (id) => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    await axios
      .get(baseUrl + `temp/deposite/${id}?page=${pageNumber}&_limit=${limit}`, {
        headers,
      })
      .then((res) => {
        setGame(res.data?.ress);
      })
      .catch((error) => {
        console.log("payment in out error", error);
      });
  };

  useEffect(() => {
    role();
  }, [pageNumber, limit]);
  console.log("payment data ", cardData);
  return (
    <div>
      <div className="leftContainer" style={{ height: "100%", marginBottom: "100px"  }}>
        {/* game-cards */}

        {cardData &&
          cardData
            .filter(
              (card) =>
                (card.reqType === "deposit" ) 
                // (card.reqType === "withdraw" && card.status === "SUCCESS") ||
                // (card.reqType === "withdraw" && card.status === "Pending")
            )

            .map((card) => {
              if (card.reqType === "deposit" ) {
                var titleMsg = "Cash added";
                var signIcon = <div style={{ color: "#198754" }}>(+)</div>;
                var amount = (
                  <div style={{ color: "#198754" }}>{card.amount}</div>
                );
                var gamesIcon = <img src={chips_add}></img>;
              } else if (
                card.reqType === "withdraw" &&
                card.status === "SUCCESS"
              ) {
                // var titleMsg = "Withdrawal Chips using " + card.Withdraw_type;
                var titleMsg = "Withdrawal Chips success";
                var signIcon = <div className="text-danger">(-)</div>;
                var amount = <div className="text-danger">{card.amount}</div>;
                var gamesIcon = (
                  <button
                    style={{
                      backgroundColor: "#198754",
                      color: "white",
                      fontFamily: "Poppins",
                      fontWeight: "600",
                      border: "none",
                      borderRadius: "5px",
                      padding:"0px 3px",
                    }}
                  >
                    success
                  </button>
                );
              } else if (
                card.reqType === "withdraw" &&
                card.status === "Pending"
              ) {
                // var titleMsg = "Withdrawal Chips using " + card.Withdraw_type;
                var titleMsg = "Withdrawal Chips success";
                var signIcon = <div className="text-danger">(-)</div>;
                var amount = <div className="text-danger">{card.amount}</div>;
                var gamesIcon = (
                  <button
                    style={{
                      backgroundColor: "#198754",
                      color: "white",
                      fontFamily: "Poppins",
                      fontWeight: "600",
                      border: "none",
                      borderRadius: "5px",
                    }}
                  >
                    pending
                  </button>
                );
              } else if (
                card.reqType !== "penalty" &&
                card.reqType !== "bonus" &&
                card.status !== "Pending" &&
                card.status !== "FAILED"
              ) {
              }
              return (
                <div
                  className="px-1 py-2 w-100"
                  style={{ borderBottom: "1px solid #ccc" }}
                >
                  <div className="d-flex align-items-stretch w-100">
                    <div
                      className=" d-flex flex-column align-items-start justify-content-center border-end "
                      style={{ width: "80px" }}
                    >
                      <span>{gamesIcon}</span>
                      <span
                        className="text-start"
                        style={{ fontSize: "0.8rem", fontFamily: "Poppins" }}
                      >
                        {dateFormat(card.createdAt).split(",")[0]}
                      </span>
                      <span
                        className="text-start"
                        style={{ fontSize: "0.8rem", fontFamily: "Poppins" }}
                      >
                        {dateFormat(card.createdAt).split(",")[1]}
                      </span>
                    </div>
                    <div className="d-flex flex-column align-items-stretch justify-content-center flex-1 w-100 px-2">
                      <div className="d-flex align-items-stretch justify-content-between">
                        <div className="d-flex gap-2">
                          <span
                            className="fw-semibold text-capitalize text-start"
                            // style="font-size: 0.9rem;"
                            style={{
                              fontSize: "0.8rem",
                              fontFamily: "Poppins",
                            }}
                          >
                            {titleMsg}
                          </span>

                          
                            {card.reqType === "withdraw" &&
                              card.status === "SUCCESS" && (
                                <div>
                                  <button
                                    type="button"
                                    className="rounded-circle d-flex align-items-center justify-content-center btn btn-primary eye-btn"
                                    
                                    data-bs-toggle="offcanvas"
                                    data-bs-target="#offcanvasBottom2"
                                    aria-controls="offcanvasBottom2"
                                  >
                                    <svg
                                    className="eye-btn-img"
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 16 16"
                                      width="1em"
                                      height="1em"
                                      fill="white"
                                   
                                    >
                                      <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z"></path>
                                      <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z"></path>
                                    </svg>
                                  </button>

                                  <div
                                    className="offcanvas offcanvas-bottom offcanvas-responsive-height"
                                    style={{
                                      height: "385px",
                                      overflowY: "auto",
                                    }}
                                    tabindex="-1"
                                    id="offcanvasBottom2"
                                    aria-labelledby="offcanvasBottomLabel"
                                  >
                                    <div className="offcanvas-header d-flex justify-content-center ">
                                      <h5
                                        className="offcanvas-title  "
                                        id="offcanvasBottomLabel"
                                        style={{
                                          fontSize: "20px",
                                          fontWeight: "900",
                                          fontFamily: "Poppins",
                                        }}
                                      >
                                        Withdrawl Details
                                      </h5>
                                      <button
                                        type="button"
                                        className="btn-close"
                                        data-bs-dismiss="offcanvas"
                                        aria-label="Close"
                                      ></button>
                                    </div>
                                    <div
                                      className="offcanvas-body d-flex justify-content-between "
                                      style={{ overflowY: "hidden" }}
                                    >
                                      <div
                                        style={{
                                          fontSize: "15px",
                                          fontWeight: "900",
                                          fontFamily: "Poppins",
                                        }}
                                      >
                                        {" "}
                                        UPI ID:
                                      </div>
                                      <div>{card.upiId}</div>
                                    </div>
                                    <hr style={{borderBottom:"1px solid", marginTop:"0"}}/>

                                    <div
                                      className="offcanvas-body  d-flex justify-content-between"
                                      style={{ overflowY: "hidden" }}
                                    >
                                      <div
                                        style={{
                                          fontSize: "15px",
                                          fontWeight: "900",
                                          fontFamily: "Poppins",
                                        }}
                                      >
                                        UTR:
                                      </div>
                                      <div>{card.utr_number}</div>
                                    </div>
                                    <hr style={{borderBottom:"1px solid", marginTop:"0"}}/>
                                    <div
                                      className="offcanvas-body  d-flex justify-content-between"
                                      style={{ overflowY: "hidden" }}
                                    >
                                      <div
                                        style={{
                                          fontSize: "15px",
                                          fontWeight: "900",
                                          fontFamily: "Poppins",
                                        }}
                                      >
                                        {" "}
                                        status:
                                      </div>
                                      <div>
                                        <button
                                       
                                          style={{
                                            backgroundColor: "#198754",
                                            color: "white",
                                            fontFamily: "Poppins",
                                            fontWeight: "600",
                                            border: "none",
                                            borderRadius: "10px",
                                            padding:"1px 3px"
                                            
                                          }}
                                        >
                                          success
                                        </button>
                                      </div>
                                    </div>
                                    <hr style={{borderBottom:"1px solid", marginTop:"0"}}/>
                                    <div
                                      className="offcanvas-body  d-flex justify-content-between"
                                      style={{ overflowY: "hidden" }}
                                    >
                                      <div
                                        style={{
                                          fontSize: "15px",
                                          fontWeight: "900",
                                          fontFamily: "Poppins",
                                        }}
                                      >
                                        {" "}
                                        Request Time:{" "}
                                      </div>
                                      <div>
                                        {dateFormat2(card.createdAt).split(",")}
                                      </div>
                                    </div>
                                    <hr style={{borderBottom:"1px solid", marginTop:"0"}}/>
                                    <div
                                      className="offcanvas-body d-flex justify-content-between "
                                      style={{ overflowY: "hidden" }}
                                    >
                                      <div
                                        style={{
                                          fontSize: "15px",
                                          fontWeight: "900",
                                          fontFamily: "Poppins",
                                        }}
                                      >
                                        {" "}
                                        Processed Time:
                                      </div>
                                      <div>
                                        {" "}
                                        {dateFormat2(card.updatedAt).split(",")}
                                      </div>
                                    </div>
                                    <hr style={{borderBottom:"1px solid", marginTop:"0"}}/>
                                    <div
                                      className="offcanvas-body "
                                      style={{ overflowY: "hidden" }}
                                    >
                                      <p className="withdraw-footerpara">
                                        नोट:- आपका Withdrawl - Success हो गया है
                                        , आप दी हुई UPI ID में चेक कर सकते है।
                                        यदि आपकी UPI ID सही है लेकिन बैंक में
                                        अभी पैसा नहीं आया है तो 3-5 दिन का
                                        इंतज़ार करें Withdrawl बैंक से 'Reversal'
                                        आते ही आपके ID में पैसा ऐड कर दिया
                                        जायेगा। यदि Success हुए 7 दिन से ऊपर हो
                                        गया है तो{" "}
                                        <Link to="/contact-us">Support</Link>{" "}
                                        पेज में से हमें संपर्क करें ।
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              )}
                        </div>
                        <div className="d-flex flex-column align-items-end justify-content-center">
                          <span
                            className=" text-end fw-bold my-1 d-flex gap-1  "
                            style={{
                              fontSize: "0.9rem",
                              fontWeight: "bold",
                              color: "#198754",
                            }}
                          >
                            {signIcon} {amount}
                          </span>
                        </div>
                      </div>
                      <div className="d-flex align-items-center justify-content-between">
                        <span
                          className="text-start text-capitalize"
                          style={{
                            fontSize: "0.7rem",
                            fontFamily: "Poppins",
                          }}
                        >
                          {card.utr_number
                            ? `UTR: ${card.utr_number}`
                            : `status: ${
                                card.status === "none" ||
                                (card.status === "Pending" &&
                                  card.reqType === "withdraw")
                                  ? "Processing"
                                  : card.status === "Pending" &&
                                    card.reqType === "deposit"
                                  ? "Cancelled"
                                  : card.status
                              }`}
                        </span>

                        <span
                          className="text-start"
                          style={{
                            fontSize: "0.7rem",
                            fontFamily: "Poppins",
                          }}
                        >
                          {card.closingBalance && (
                            <div>Closing Balance :{card.closingBalance}</div>
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
        {(cardData === undefined ||
          cardData.length === 0 ||
          !cardData.some(
            (card) =>
              (card.reqType === "deposit" ) 
          )) && (
          <div className="text-center">
            <img
              src={process.env.PUBLIC_URL + "/Images/refer_null.png"}
              alt="no data"
              className="img-fluid"
              style={{ marginTop: "25%" }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Deposit;
