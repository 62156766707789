import React, { useState, useEffect } from "react";
import "../css/gamehistory.css";
import ReactPaginate from "react-paginate";
import axios from "axios";
import cancel from "../../assets/images/history_icons/cancel.png";

const Penalty = () => {
  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const nodeMode = process.env.NODE_ENV;
  if (nodeMode === "development") {
    var baseUrl = beckendLocalApiUrl;
  } else {
    baseUrl = beckendLiveApiUrl;
  }
  const [user, setUser] = useState();
  let limit = 50;
  const [pageNumber, setPageNumber] = useState(0);
  const [numberOfPages, setNumberOfPages] = useState(0);

  const role = async () => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    await axios.get(baseUrl + `me`, { headers }).then((res) => {
      setUser(res.data);

      Allgames(res.data._id, pageNumber, limit);
    });
  };

  const dateFormat = (e) => {
    const date = new Date(e);
    const newDate = date.toLocaleString("default", {
      month: "short",
      day: "numeric",

      hour: "numeric",
      hour12: true,
      minute: "numeric",
    });
    return newDate;
  };

  const [cardData, setGame] = useState();

  const Allgames = async (id) => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    await axios
      .get(baseUrl + `temp/deposite/${id}?page=${pageNumber}&_limit=${limit}`, {
        headers,
      })
      .then((res) => {
        setGame(res.data.ress);
        setNumberOfPages(res.data.totalPages);
      });
  };

  useEffect(() => {
    role();
  }, [pageNumber, limit]);

  return (
    <div>
      <div className="leftContainer" style={{ height: "100%", marginBottom: "100px"  }}>
        {cardData &&
          cardData
            .filter((card) => card.reqType === "penalty")
            .map((card) => {
              var titleMsg = "Penalty";
              var signIcon = <div className="text-danger">(-)</div>;

              return (
                <div
                  className="px-1 py-2 w-100"
                  style={{ borderBottom: "1px solid #ccc" }}
                >
                  <div className="d-flex align-items-stretch w-100">
                    <div
                      className=" d-flex flex-column align-items-start justify-content-center border-end "
                      style={{ width: "80px" }}
                    >
                      <span>
                        <img src={cancel} alt="/" />
                      </span>
                      <span
                        className="text-start"
                        style={{ fontSize: "0.8rem", fontFamily: "Poppins" }}
                      >
                        {dateFormat(card.createdAt).split(",")[0]}
                      </span>
                      <span
                        className="text-start"
                        style={{ fontSize: "0.8rem", fontFamily: "Poppins" }}
                      >
                        {dateFormat(card.createdAt).split(",")[1]}
                      </span>
                    </div>
                    <div className="d-flex flex-column align-items-stretch justify-content-center flex-1 w-100 px-2">
                      <div className="d-flex align-items-stretch justify-content-between">
                        <div className="d-flex flex-column">
                          <span
                            className="fw-semibold text-capitalize text-start"
                            style={{
                              fontSize: "0.8rem",
                              fontFamily: "Poppins",
                            }}
                          >
                            {titleMsg}
                          </span>
                        </div>
                        <div className="d-flex  align-items-end justify-content-center gap-1 ">
                          <span
                            className=" text-end fw-bold my-1"
                            style={{
                              fontSize: "0.9rem",
                              fontWeight: "bold",
                              color: "red",
                            }}
                          >
                            {signIcon}
                          </span>
                          <span
                            className=" text-end fw-bold my-1"
                            style={{
                              fontSize: "0.9rem",
                              fontWeight: "bold",
                              color: "red",
                            }}
                          >
                            {card.amount}
                          </span>
                        </div>
                      </div>
                      <div className="d-flex align-items-center justify-content-between">
                        <span
                          className="text-start text-capitalize"
                          style={{ fontSize: "0.7rem", fontFamily: "Poppins" }}
                        >
                          {card.reqType === "penalty" && (
                            <div>status: Penalty by admin</div>
                          )}
                        </span>
                        <span
                          className="text-start"
                          style={{ fontSize: "0.7rem", fontFamily: "Poppins" }}
                        >
                          {card.closingBalance && (
                            <div> Closing Balance :{card.closingBalance}</div>
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}

        {(!cardData ||
          cardData.length === 0 ||
          cardData.every((card) => card.reqType !== "penalty")) && (
          <div className="text-center">
            <img
              src={process.env.PUBLIC_URL + "/Images/refer_null.png"}
              alt="no data"
              className="img-fluid"
              style={{ marginTop: "25%" }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Penalty;
